import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData, putData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from "../../../components/Button";
import { useReactToPrint } from 'react-to-print';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
import { Invoice } from './invoice';

const PurchaseOrderView = () => {

    const componentRef = useRef();
    const dispatch = useDispatch();

    /*Fetch user id */
    const navigate = useNavigate();
    const { id } = useParams();
    /*Inital state data*/
    const [orderData, setOrderData] = useState(null);
    const [historyData, setHistoryData] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [isItemUpdate, setItemUpdate] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);

    const itemChangeHandler = (event, index) => {
        orderData.items[index].update_status_id = event.target.value
        console.log("orderData : ",orderData)
        
        setItemUpdate(false)
      
    }

    const updateItems = () => {
        setItemUpdate(true)
        putData("/purchase/orders/updateitems/status", orderData.items).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                if (responseData.success) {
                    AlertSuccess(responseData.message)
                    getOrderData()
                }
            }
        });
    }
    /*Get order data*/
    const getOrderData = () => {
        getData("/purchase/orders/details/" + id).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                if (responseData.success) {
                    setOrderData(responseData.data);
                    setOrderItems(responseData.data.items)
                    setHistoryData(responseData.data.history)
                } else {
                    AlertError(responseData.message);
                    navigate("/purchaseorders");
                }
            }
        });
    };

    const updateRowHandler = () => {
        let nextState = !isUpdate
        if (!nextState) {
            getOrderData();
        }
        setIsUpdate(nextState)
    }
    useEffect(() => {

        getOrderData();
        dispatch(setPageTitle('Purchase Order Details'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name": 'Purchase Orders',
                "link": '/purchaseorders',
            },
            {
                "name": 'Purchase Order Details',
                "link": '/purchaseorders/details/' + id,
            }
        ]))
        // eslint-disable-next-line
    }, [id, navigate, dispatch]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const rowUpdatehandler = (index, value, type) => {

        let items = [...orderItems];
     

        if (type === 'quantity')
          if (items) {

           const quantityValue = parseInt(value)
          
           items[index].total_quantiy = items[index].unit_quantity * quantityValue;
           let lastprice = (items[index].price/items[index].quantity).toFixed(2);
           let lastPriceValue = (lastprice !== null && lastprice !== undefined)?lastprice:items[index].price;
       
           const lastPrice = parseFloat(lastPriceValue * quantityValue);
       
           items[index].price = lastPrice.toFixed(2);
           items[index].quantity = quantityValue;
           items[index].price_per_unit = quantityValue*items[index].unit_quantity?(quantityValue*items[index].unit_quantity)/lastPrice.toFixed(1):0;
           orderData.subtotal = items[index].price;
            let vat = orderData.vat_rate;
            let totalVatPrice = (orderData.subtotal*vat)/100;
            orderData.vattotal = parseFloat(totalVatPrice).toFixed(2);
            let totalAmount = parseFloat(orderData.subtotal)+parseFloat(orderData.vattotal);
           items[index].total_amount = totalAmount.toFixed(2);

           orderData.total = items[index].total_amount;

           orderData.total = orderData.delivery_charge?parseFloat(orderData.delivery_charge)+parseFloat(totalAmount):parseFloat(orderData.total)
           orderData.total = orderData.commissions_surcharges?parseFloat(orderData.commissions_surcharges)+parseFloat(orderData.total):parseFloat(orderData.total)

          } else {
            items[index][type] = value;
          }

      
      console.log("items : ",items)
        setOrderData(orderData)
        setOrderItems(items)

    }
    const updateRowItemsHandler = () => {
        if (window.confirm("Are you sure wan't to update items?")) {
            putData("/purchase/orders/updateitems/row", orderData.items).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        AlertSuccess(responseData.message)
                        setIsUpdate(false)
                        getOrderData()
                    }
                }
            });
        }
    }
    const approveorder = () => {
        if (window.confirm("Are you sure wan't to approve this order?")) {
          console.log("id:"+id)
            putData("/purchase/orders/approve/" + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        AlertSuccess(responseData.message)
                        setIsUpdate(false)
                        getOrderData()
                    }
                }
            });
        }
    }

    return (
        <DefaultLayout>
      <section className="section invoice-section" >
        <div className="row">
            <div className="card">
            <div className="card-body mt-3">
     
      <div className="row mx-2" >
    
          <div className="col-12">
          <div className="order-detail-col">

 
          { orderData &&   
            <div className="grid invoice mt-3 mb-3" >
              <div className="grid-body">
                <div className="invoice-title">
                 
                  <div className="row">
                    <div className="col-md-6 col-6">
                    <h4>Order #{orderData.sage_po_code}</h4>
                    </div>
                    <div className="col-md-6 col-6">
                    {!isUpdate &&
                      <PrimaryButton clickfun={handlePrint} buttonClass="primary view-btn float-end no-print" buttonText="Print" iclassName="file-earmark-pdf-fill" />
                    }
                    <div className={'hide'}>
                     <Invoice ref={componentRef} orderData={orderData} orderItems={orderItems} />
                    </div>
                      
                    </div>
                  </div>
                </div>
       
                <div className="row mt-3">
                  <div className="col-md-5 col-12 mb-2">
                    
                  <div className="billed-col">
                  <h6 className="fw-bold">Billed To:</h6>
                    <address>
                   
                      <b> {orderData.supplier_name} </b><br/>
                      {orderData.supplier_address1},{orderData.supplier_address2}<br/>
                      {orderData.supplier_city} ,  {orderData.supplier_county}<br/>
                      {orderData.supplier_postcode}<br/>
                       <i className="bi bi-person-badge"></i> {orderData.supplier_contact_name? orderData.supplier_contact_name : 'N/A'} <br/>
                       <i className="bi bi-phone-vibrate"></i> {orderData.supplier_contact_number? orderData.supplier_contact_number : 'N/A'}
                    </address>
                  </div>  </div>
                  <div className="col-md-5 col-12 text-left offset-md-2 mb-2">
                      <div className="billed-col">
                        <h6 className="fw-bold">Order Information:</h6>
                       <address>
                      
                      <span>Delivery Date: </span>{orderData.delivery_date}<br/>
                      <span>Tax Code: </span>{orderData.tax_code}<br/>
                      <span>Commodity Code: </span>{orderData.commoditycode}<br/>
                      <span>Country of Origin: </span>{orderData.country_of_origin}<br/>
                      <span>Delivery method / haulier: </span>{orderData.haulier}<br/>
                      <span>Collection Location: </span>{orderData.loading_place}<br/>
                      <span>Status: </span> <span className={`fw-bold badge bg-${orderData.status_bgcolor}`}>{orderData.order_status}</span><br/>
                      </address>
                    </div>   
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 table-responsive">
                    <h4>ORDER SUMMARY </h4>
                    {orderData.is_update_history && <p className="text-danger">Major difference in the product quantity or unit quantity while receiving order.</p>}
                    <table className="table table-striped">
                      <thead className="order-smr">
                        <tr className="line">
                        <th scope="col"><strong>#</strong></th>
                        <th scope="col"><strong>GRN</strong></th>
                        <th scope="col"><strong>Description</strong></th>
                        <th scope="col"><strong>Locations</strong></th>
                        <th scope="col"><strong>Quantity</strong></th>
                        <th scope="col"><strong>Unit</strong></th>
                        <th scope="col"><strong>Sub Unit</strong></th>
                        <th scope="col"><strong>Qty. Per Unit</strong></th>
                        <th scope="col"><strong>Price Per Unit</strong></th>
                        <th scope="col"><strong>Total Quantity</strong></th>
                        <th scope="col"><strong>Total Price(Ex.VAT)</strong></th>
                        {/* <th scope="col"><strong>VAT</strong></th> */}
                        <th scope="col" className="text-right" ><strong>Total  (In.VAT)</strong></th>
                        <th scope="col" colSpan={[3,5].includes(orderData.status_id) ? "4" : "2"}></th>
                        </tr>
                      </thead>
                      <tbody className="order-sum">
                      {orderItems.length > 0 && orderItems.map((item, index) => (

                        <React.Fragment key={index}>
                        { !isUpdate && <tr key={index} className={ item.item_status_id === 7 ? 'bg-danger text-white' :''}>
                            <th scope="row"> {index + 1 } </th>
                            <td>{item.grn || '-'}</td>
                            <td>{item.goods_description}
                              <small className={`mx-2 fw-bold text-wrap badge bg-${item.status_bgcolor}`}>  {item.itemstatus} </small>
                              <small className={`mx-2 fw-bold text-wrap badge bg-secondary`}>  QA Required : {item.is_qa_required === 1 ? 'Yes':'No'} </small>
                              <small className={`mx-2 fw-bold text-wrap badge bg-secondary`}>  Docket : {item.docker_correct === 1 ? 'No':'Yes'} </small>
                             
                              {item.approvedby && <small className={`mx-2 fw-bold text-wrap badge bg-secondary`}>  Approved By : {item.approvedby} </small> }
                            </td>
                            <td className="font-9x">{item.locations}</td>
                            <td> {item.quantity }
                                {/*<small>{item.unit}</small><br/> */ }
                                {item.update_history.length > 0 && item.update_history.map((updatehis,index)=>(
                                  // item.quantity !==  updatehis.quantity &&
                                  <React.Fragment  key={index}>
                                  <small className="text-danger bw-bold">{updatehis.role}:{updatehis.quantity}  </small><br/>
                                  </React.Fragment>
                                ))}  
                            </td>
                            <td>{item.unit }<br/>
                                {item.update_history.map((updatehis,index)=>(
                                    //  item.unit_quantity !==  updatehis.unit_quantity &&
                                     <React.Fragment  key={index}>
                                      <small className="text-danger bw-bold">{updatehis.unit_quantity}  </small><br/>
                                     </React.Fragment>
                                ))} 
                            </td>
                            <td>{item.sub_unit !== null ? item.sub_unit : ""}</td>
                            <td>{item.unit_quantity !== null ? item.unit_quantity : ""}</td>

                            <td>{item.unit_quantity ?parseFloat((item.quantity * item.unit_quantity)/item.price).toFixed(2):0}</td>
                          
                            <td>{item.quantity * item.unit_quantity}</td>

                            <td className="text-right"><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{item.price}</td>
                            {/* <td><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{item.tax_amount}</td> */}
                            <td  className="text-right"><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{item.total_amount}</td>
                           
                            {item.item_status_id === 3 &&  <td colSpan={4} >
                            <select className="form-control sm-approval-select" defaultValue={item.item_status_id} onChange={ (e) => itemChangeHandler(e,index)}>
                                                    <option value={3} disabled>SM Approval</option>
                                                    <option value={4}>Approve</option>
                                                    <option value={9}>Reject</option>
                                                </select>
                            </td> 
                            }
                            
                        </tr>
                      }
                      {isUpdate && <tr key={index} className={ item.item_status_id === 7 ? 'bg-danger text-white' :''}>
                            <th scope="row">{index + 1 }</th>
                            <td>{item.grn}</td>
                            <td>{item.goods_description}</td>
                            <td>{item.locations}</td>
                            <td><input type="number" min={1} className="form-control" defaultValue={item.quantity } onInput={(e)=> rowUpdatehandler(index, e.target.value, 'quantity')} />
                            {item.update_history.length > 0 && item.update_history.map((updatehis,index)=>(
                                  // item.quantity !==  updatehis.quantity &&
                                  <React.Fragment  key={index}>
                                  <small className="text-danger bw-bold">{updatehis.role}:{updatehis.quantity}</small><br/>
                                  </React.Fragment>
                                ))} 
                            </td>
                            
                            <td>
                              {item.unit}
                            {/*  <input type="number" min={1} className="form-control" defaultValue={item.unit_quantity } onInput={(e)=> rowUpdatehandler(index, e.target.value, 'unit_quantity')} /> */}
                            {item.update_history.map((updatehis,index)=>(
                                    //  item.unit_quantity !==  updatehis.unit_quantity &&
                                     <React.Fragment  key={index}>
                                      <small className="text-danger bw-bold">{updatehis.unit_quantity}</small><br/>
                                     </React.Fragment>
                                ))} 
                            </td>
                            <td>{item.sub_unit !== null ? item.sub_unit : ""}</td>
                            <td>{item.unit_quantity}</td>
                            <td>{item.unit_quantity ?parseFloat((item.quantity * item.unit_quantity)/item.price).toFixed(2):0}</td>
                        
                            <td>{item.quantity * item.unit_quantity}</td>

                            <td>{orderData.is_update_history ? item.price : <input className="form-control"  value={item.price } onInput={(e)=> rowUpdatehandler(index, e.target.value, 'price')} />}</td>
                            {/* <td>{item.tax_amount}</td> */}
                            <td className="float-end" colSpan={3} ><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{item.total_amount }</td>
                            
                        </tr>
                      }
                       
                      </React.Fragment>
                      ))}
                      
                      {(orderData.status_id === 1 || orderData.is_update_history) && 
                      <tr>
                          <td colSpan="13">
                          {isUpdate &&
                            <PrimaryButton buttonText='Return' buttonClass='warning float-end' title='Cancel' clickfun={()=> updateRowHandler() } iclassName='arrow-counterclockwise' /> 
                          }
                          {isUpdate &&
                            <PrimaryButton buttonText='Update Items' buttonClass='danger float-end' title='Update Items' clickfun={()=> updateRowItemsHandler() } iclassName='database' /> 
                          }
                          {!isUpdate &&
                          <React.Fragment>
                            { orderData.status_id < 7 && <PrimaryButton buttonText='Edit Items' buttonClass='success float-end' title='Edit Items' clickfun={()=> updateRowHandler() } iclassName='pencil-fill' />}
                            
                            { orderData.status_id === 1 && <PrimaryButton disabled={!isItemUpdate} buttonText='Approve Order' buttonClass='info float-end text-white' title='Approve Order' clickfun={()=> approveorder() } iclassName='database' /> }
                          </React.Fragment>
                          
                          }
                          </td>
                        </tr>
                      } 
                      {orderData.status_id === 5 &&
                        <tr>
                          <td colSpan="13"> <PrimaryButton disabled={!isItemUpdate} buttonText='Complete Order' buttonClass='success float-end text-white' title='Complete Order' clickfun={()=> approveorder() } iclassName='database' /> 
                        </td>
                       </tr>
                      } 
                      {orderData.status_id === 3 && !isUpdate && 
                      <tr>
                          <td className="text-right" colSpan="13">
                          <PrimaryButton disabled={isItemUpdate} buttonText='Update Items' buttonClass='success float-end' title='Update Items' clickfun={()=> updateItems() } iclassName='database' />
                          </td>
                          
                        </tr>
                      } 
                      <tr className="mt-5">
                          <td  colSpan={orderData.status_id === 3 ? "6" : "5"}></td>
                          <td className="text-right"  colSpan={[3,5].includes(orderData.status_id) ? "6" : "3"}>SubTotal</td>
                          <td className="text-right"><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{orderData.subtotal}</td>
                      </tr>
                      
                      
                      <tr>
                          <td colSpan={orderData.status_id === 3 ? "6" : "5"}></td>
                          <td className="text-right" colSpan={[3,5].includes(orderData.status_id) ? "6" : "3"}>Taxes ({orderData.vat_rate ? orderData.vat_rate : 0}%)</td>
                          <td className="text-right"><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{ orderData.vattotal > 0 ? orderData.vattotal : 0 }</td>
                      </tr>

                      <tr>
                          <td colSpan={orderData.status_id === 3 ? "6" : "5"}></td>
                          <td className="text-right"  colSpan={[3,5].includes(orderData.status_id) ? "6" : "3"}>Delivery charge (if applicable):</td>
                          <td className="text-right"><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{orderData.delivery_charge}</td>
                      </tr>

                       <tr>
                          <td colSpan={orderData.status_id === 3 ? "6" : "5"}>
                          </td><td className="text-right"  colSpan={[3,5].includes(orderData.status_id) ? "6" : "3"}>Commissions / Surcharges (if applicable)</td>
                          <td className="text-right"><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{orderData.commissions_surcharges}</td>
                        </tr>

                        <tr>
                          <td colSpan={orderData.status_id === 3 ? "6" : "5"}>
                          </td><td className="text-right total-col"  colSpan={[3,5].includes(orderData.status_id) ? "6" : "3"}><strong>Total</strong></td>
                          <td className="text-right  total-col"><strong><span dangerouslySetInnerHTML={{ __html: orderData.currency_symbol }}/>{orderData.total}</strong></td>
                        </tr>

                      </tbody>
                    </table>
                  </div>            
                </div>
              </div>
            </div> 
  }

  </div>
  
    {/* order history */}
    {historyData && historyData.length > 0 && 
    <div className="row" >
      <div className="card">
        <div className="card-body mt-3">
            <h5>Order history</h5>
        </div>
        <div className="card-body mt-3">
              <ul className="notification">
              { historyData.map((history, hIndex) => ( 
                  <li key={hIndex}>
                      <div className="notification-time"><span className="date fw-bold"> {history.date}</span><span className="time ms-2 "> {history.time}</span></div>
                      <div className="notification-icon"><span></span></div>
                      <div className="notification-time-date mb-2 d-block d-md-none"><span className="date fw-bold"> {history.date}</span><span className="time ms-2 "> {history.time}</span></div>
                      <div className="notification-body">
                          <div className="media mt-0">
                              <div className="media-body ms-3 d-flex">
                                
                                  <div className="">
                                  <p className="tx-14 text-dark mb-2 tx-semibold fw-bold" dangerouslySetInnerHTML={{ __html:history.heading }} ></p>
                                    { history.keys.length > 0
                                    &&
                                      <table className="table table-striped table-hover text-left">
                                        <thead>
                                        <tr>
                                        {history.keys.map((key, kIndex) => (  <th style={{textTransform: 'capitalize'}} key={kIndex}>
                                         <b> {key.replace('_', ' ')}</b>
                                        </th> )) }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {history.values.map((value, vIndex) => ( 
                                         
                                        <tr key={vIndex}>
                                           { history.keys.map((key, kIndex) => ( 
                                              <td  key={kIndex}> 
                                              { value[key] ? value[key] : '-'} 

                                              <span style={{textTransform: 'capitalize'}}>

                                              {value['sub_type'] && (value['sub_type'] === 'item_reject' || value['sub_type'] === 'item_approved') ? ' ('+value['sub_type'].replace('_',' ')+')' : '' } 
                                              </span>
                                              
                                              </td>
                                            )) 
                                           }
                                         </tr>
                                        
                                         )) 
                                         }
                                         </tbody>
                                      </table>
                                    }
                                    {history.keys.length === 0 && 
                                    <React.Fragment>
                                      <p className="tx-14 text-dark mb-0 tx-semibold" dangerouslySetInnerHTML={{ __html:history.description }} ></p>
                                    </React.Fragment>
                                    
                                  }
                                      
                                  </div>
                              </div>
                          </div>
                      </div>
                  </li>
                    ))}
              </ul>  
        </div>
    </div>
</div>

}
            
</div>

        </div>
        </div>
        </div>
        </div>
      </section>
    </DefaultLayout>
    );
};
export default PurchaseOrderView;